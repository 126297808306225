<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Sale Order Details
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6" >
                                 <!-- <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Shop Name </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.shopName" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Name </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.name" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Phone </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.phone" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Address </label>
                                        <div class="col-sm-9">
                                            <textarea v-model="orderInfo.address" class="form-control text-capitalize" disabled></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="padding-left: 0px;">
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Date </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.date" class="form-control" disabled>
                                        </div>
                                    </div>
                                </div>
                                    <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Status </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.status" class="form-control text-capitalize" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12"  style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">Total Price </label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model="orderInfo.totalPrice" class="form-control text-capitalize" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="padding-left: 0px;font-size: 18px;font-weight: 100;">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label"></label>
                                        <div class="col-sm-9">
                                            <!-- <button class="btn btn-success" style="width:100%;" v-b-modal.bv-modal-status @click="statusDetail()">Status Change</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Shop</th>
                                                <th>Product</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Total Price</th>
                                                <th>Status</th>
                                                <!-- <th class="th-width-100">Option</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td>{{data.shop}}</td>
                                                <td>{{data.productName}}</td>
                                                <td>{{data.quantity}}</td>
                                                <td>{{data.price}}</td>
                                                <td>{{data.totalPrice}}</td>
                                                <td>
                                                    <span class="badge badge-pill badge-primary" v-if="data.status == 'pending'">Pending</span>
                                                    <span class="badge badge-pill badge-success" v-if="data.status == 'completed'">Completed</span>
                                                    <span class="badge badge-pill badge-danger"  v-if="data.status == 'reject'">Reject</span>
                                                </td>
                                                <!-- <td style="padding: 0px;"><button class="btn btn-option text-primary" v-b-modal.bv-modal-infostatus @click="statusInfo(data)"> <i class="fas fa-edit"></i></button></td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-status" ref="my-modal" title="Status" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger">{{this.alert}}</p>
                <select class="form-control" v-model="status">
                    <option value="reject">Reject</option>
                    <option value="pending">Pending</option>
                    <option value="confirm">Confirm</option>
                    <option value="ontheway">On The Way</option>
                    <option value="completed">Completed</option>
                </select>
            </div>
            <b-button class="mt-3 btn-success" block @click="statusUpdate()">Change</b-button>
        </b-modal>
        <b-modal id="bv-modal-infostatus" ref="my-modal" title="Info Status" hide-footer>
            <div class="d-block text-center">
                <p class="text-danger">{{this.alert}}</p>
                <input type="text" class="form-control" v-model="orderInfoRequest.productName" disabled><br>
                <input type="number" class="form-control" v-model="orderInfoRequest.quantity"><br>
                <select class="form-control" v-model="orderInfoRequest.status">
                    <option value="pending">Pending</option>
                    <option value="reject">Reject</option>
                    <option value="completed">Completed</option>
                </select>
            </div>
            <b-button class="mt-3 btn-success" block @click="infoStatusUpdate()">Change</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'saleOrderDetail',
        metaInfo: {
            title: "Sale Order Details",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                status: "",
                dataList: [],
                orderInfo: {
                    shopName: "",
                    name: "",
                    phone: "",
                    address: "",
                    date: "",
                    status: "",
                    totalPrice: "",
                },
                orderInfoRequest: {
                    infoId: "",
                    productName: "",
                    quantity: "",
                    status: ""
                },
                filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    shopId: "",
                    fromDate: "",
                    toDate: "",
                    status: "",
                }
            }
        },
        watch: {
            '$route' (to, from) {
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                saleOrderDetailAction: 'saleOrderDetailAction',
                saleOrderInfoAction: 'saleOrderInfoAction',
                saleOrderUpdateAction: 'saleOrderUpdateAction',
                saleOrderDetailUpdateAction: 'saleOrderDetailUpdateAction'
            }),
            async getDetail(){
				let option = {
					id: this.id
				}
				await this.saleOrderInfoAction({
					...option
				}).then(res => {
					this.orderInfo.shopName = res.data.data.shop.name
                    this.orderInfo.name = res.data.data.name
                    this.orderInfo.phone = res.data.data.phone
                    this.orderInfo.address = res.data.data.address
                    this.orderInfo.date = res.data.data.created_at
                    this.orderInfo.totalPrice = res.data.data.total_price
                    this.orderInfo.status = res.data.data.status
                    this.status = res.data.data.status
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
            async fetchData() {
                this.isLoading = true
                let option = {
					saleOrderId: this.id
				}
                await this.saleOrderDetailAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            back(data) {
                this.$router.replace({ path: 'bank-transfer-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, shopId: this.filter.shopId, fromDate: this.filter.fromDate, toDate: this.filter.toDate, status: this.filter.status } }).catch(()=>{})
            },
            statusDetail(){
                this.alert = ""
            },
            statusInfo(data){
                this.alert = ""
                this.orderInfoRequest.infoId = data.id
                this.orderInfoRequest.productName = data.productName
                this.orderInfoRequest.quantity = data.quantity
                this.orderInfoRequest.status = data.status
            },
            async statusUpdate() {
				this.isLoading = true
				let option = {
					id:  this.id,
                    status: this.status
				}
				await this.saleOrderUpdateAction({
					...option
				}).then(res => {
                    this.getDetail()
                    this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            async infoStatusUpdate() {
				this.isLoading = true
				let option = {
					id:  this.orderInfoRequest.infoId,
                    quantity: this.orderInfoRequest.quantity,
                    status: this.orderInfoRequest.status
				}
				await this.saleOrderDetailUpdateAction({
					...option
				}).then(res => {
                    this.getDetail()
                    this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			}
        },
        mounted() {
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.id = this.$route.query.id
            this.filter.shopId = this.$route.query.shopId
            this.filter.fromDate = this.$route.query.fromDate
            this.filter.toDate = this.$route.query.toDate
            this.filter.status = this.$route.query.status
            this.fetchData()
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>